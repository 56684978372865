import Prism from './prism';
import Faces from './faces';
import Typing from './typing';
import Quote from './quote';
import Sentry from './sentry';
import Webmentions from './webmentions';
import Rss from './rss';

import '../css/index.css';

Sentry();
Prism();
Faces();
Typing();
Quote();
Webmentions();
Rss();
